import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../layout/sidebar/sidebar.jsx";
import UserHeader from "../layout/header.jsx";
import options from "../layout/sidebar/dashboard-options";
import * as PatientActions from "../../redux/actions/patient-actions";
import * as TaskActions from "../../redux/actions/task-actions";
import { Progress } from "antd";
import { Layout, Row, Col, Avatar, Typography, List, Spin, Tooltip, Button, Modal} from "antd";
import Icon from "@ant-design/icons";
import { ChatSvg } from "../shared/svg/chatlg";
import { ServeySvg } from "../shared/svg/servey";
import { DataSvg } from "../shared/svg/dataicon";
import { DairySvg } from "../shared/svg/dairyicon";
import { AdverseEventSvg } from "../shared/svg/adverseevents";
import { LabSvg } from "../shared/svg/labicon";
import { PaymentSvg } from "../shared/svg/paymenticon";
import { PhotogallerySvg } from "../shared/svg/photogallery";
import { ProgressSvg } from "../shared/svg/progress";
import { TaskListSvg } from "../shared/svg/tasklist";
import { StudyOverviewSvg } from "../shared/svg/studyoverview";
import { RightCaretSvg } from "../shared/svg/rightcaret";
import moment from "moment-timezone";
import { notifyUser } from "../../services/notification-service";
import firebase from "../../../../src/config/firebase";
import Config from "../../config";
import { StudyDrugLogSvg } from "../shared/svg/studydruglog.jsx";
import { AuditOutlined } from "@ant-design/icons";


const dateFormathh = "MM/DD/YYYY";
const { Header, Sider, Content } = Layout;
const ChatIcon = (props) => <Icon component={ChatSvg} {...props} />;
const ServeyIcon = (props) => <Icon component={ServeySvg} {...props} />;
const DataIcon = (props) => <Icon component={DataSvg} {...props} />;
const DairyIcon = (props) => <Icon component={DairySvg} {...props} />;
const AdverseEventIcon = (props) => (
  <Icon component={AdverseEventSvg} {...props} />
);
const LabIcon = (props) => <Icon component={LabSvg} {...props} />;
const PaymentIcon = (props) => <Icon component={PaymentSvg} {...props} />;
const PhotogalleryIcon = (props) => (
  <Icon component={PhotogallerySvg} {...props} />
);
const ProgressIcon = (props) => <Icon component={ProgressSvg} {...props} />;
const TaskListIcon = (props) => <Icon component={TaskListSvg} {...props} />;
const StudyOverviewIcon = (props) => (
  <Icon component={StudyOverviewSvg} {...props} />
);
const RightCaretIcon = (props) => <Icon component={RightCaretSvg} {...props} />;
const StudyDrugLogIcon = (props) => (
  <Icon component={StudyDrugLogSvg} {...props} />
);

class ViewPatientProgress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      patientData: undefined,
      tasks: undefined,
      loading: true,
      submitted: false,
      selectedCol: "progress",
      allPendingTasks: undefined,
      sendTasks: false,
      duplicateTasks: false,
      week: undefined,
    };
  }

  async componentDidMount() {
    let studyId = localStorage.getItem("studyId");
    let patientId = this.props.match.params.id;
    // await this.props.getPatientById(patientId);
    if (studyId == 2) {
      await this.props.getPatientByIdJanssen(patientId);
    } else if (studyId == 4) {
      await this.props.getPatientByIdNih(patientId);
    } else {
      await this.props.getPatientById(patientId);
    }
    if (
      this.props.patientData &&
      this.props.patientData.length > 0 &&
      this.props.patientData[0]
    ) {
      this.setState({ loading: false });
      let localStudyId = localStorage.getItem("studyId");
      let patId = this.props.patientData[0].studyId
      if (localStudyId != patId || !patId) {
        if (localStudyId === "3") {
          this.props.history.push("/patients/1");
          notifyUser("You are not authorized to view this Patient!", "error");
        } else {
          this.props.history.push("/dashboard");
          notifyUser("You are not authorized to view this Patient!", "error");
        }
      }
    } else if (this.props.patientDataError) {
      notifyUser(this.props.patientDataError, "error");
      this.props.history.push("/patients/1");
    }
    if (studyId == 1) {
      await this.props
      .getAllTasksList(studyId, patientId)
      .then((resp) => {
        this.setState({
          loading: false,
          tasks: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    }else if(studyId == 4){
      await this.props
      .getNihAllTasksList(studyId, patientId)
      .then((resp) => {
        this.setState({
          loading: false,
          tasks: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    } else {
      await this.props
      .getJanssenAllTasksList(studyId, patientId)
      .then((resp) => {
        this.setState({
          loading: false,
          tasks: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    }
    
    // console.log()
  }

  updateTasks = (action) => {
    if (action === 'send tasks') {
      this.setState({ sendTasks: true });
    }
    if (action === 'delete tasks') {
      this.setState({ duplicateTasks: true });
    }
  }

  async componentDidUpdate() {
    if (this.state.sendTasks === true || this.state.duplicateTasks === true) {
      let studyId = localStorage.getItem("studyId");
      let patientId = this.props.match.params.id;
      await this.props
        .getAllTasksList(studyId, patientId)
        .then((resp) => {
          this.setState({
            loading: false,
            tasks: resp == null ? resp : resp.data,
            sendTasks: false,
            duplicateTasks: false
          });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    }

  }

  toggleSidebar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  goToChat = () => {
    var patientId = this.props.match.params.id;
    var ref = firebase.firestore().collection("chatrooms");
    var type = Config.server.type;
    ref
      .where(`users.${patientId + type}`, "==", true)
      .get()
      .then((docs) => {
        let room = {};
        docs.forEach((snapshot) => {
          room = snapshot.data();
          room.id = snapshot.id;
        });
        this.props.history.push("/chat/" + patientId);
      });
  };

  downloadPatientData = async() => {
    let studyId = localStorage.getItem("studyId");
    var patientId = this.props.match.params.id;
    var response = await this.props.downloadPatientTasks(studyId, patientId);
    var a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob([response], { type: 'application/octet_stream' }));
    a.download = "patientData.zip";

    document.body.appendChild(a)
    a.click();
    document.body.removeChild(a)
    await this.props.deleteDirectoryFiles();
  };


  info = async() => {
    Modal.info({
      // title: 'This is a notification message',
      content: (
        <div>
          No completed tasks or surveys exist for the user.
        </div>
      ),
      onOk() {},
    });
  };

  goToDiary = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-diary/" + patientId);
  };

  goToPhotoGallery = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/photo-gallery/" + patientId);
  };

  goToAdverseEvents = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/adverse-events/" + patientId);
  };

  goToActivityData = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/activity-data/" + patientId);
  };

  goToEASiScoring = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-head/" + patientId);
  };

  goToTask = (taskName, id) => {
    var patientId = this.props.match.params.id;
    if (taskName === "Medication usage") {
      this.props.history.push("/medication/" + patientId);
    }
    else {
      this.props.history.push("/surveyReview/" + patientId + "/" + id);
    }
  };

  goToStudyDrugLog = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/study-drug-log/" + patientId);
  };

  goToLab = (id) => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/lab/" + patientId);
  };

  goToFlares = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/flares/" + patientId);
  }

  render() {
    const { submitted, selectedCol } = this.state;
    const task = this.state.tasks ? this.state.tasks.tasks : [];
    const currentTasks = this.state.tasks ? this.state.tasks.currentTasks : [];
    const overduetasks = this.state.tasks ? this.state.tasks.overdueTasks : [];
    const studyId = localStorage.getItem("studyId");

    const oldDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].dateOfBirth
    const dateType = !isNaN(Date.parse(oldDate))
    let finalDate = "";
    if (oldDate && dateType == true) {
      finalDate = moment(oldDate).format("MM/DD/YYYY")
    } else if (oldDate && dateType == false) {
      finalDate = moment(new Date(Number(oldDate))).format("MM/DD/YYYY")
    } else {
      finalDate = "N/A"
    }

    // Patient Start End time
    const notificationEndDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate;
    const notificationTimezone = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationTimezone;
    const endDateInTimezone = moment.tz(notificationEndDate, notificationTimezone);
    let formattedTimeEnd = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate) {
      formattedTimeEnd = endDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeEnd = "";
    }
    const formattedTimeEndData = formattedTimeEnd;

    const notificationStartDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate;
    const startDateInTimezone = moment.tz(notificationStartDate, notificationTimezone);
    let formattedTimeStart = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate) {
      formattedTimeStart = startDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeStart = "";
    }
    const formattedTimeStartData = formattedTimeStart;
    // Patient Start End time end

    // const allTasks = this.state.tasks ? this.state.tasks.tasks : [];
    const completedTasks = this.state.tasks ? this.state.tasks.completedTasks : [];
    // allTasks && allTasks.length > 0 && allTasks.map((task) => {
    //   if ((task.taskStatus).toLowerCase() === "completed") {
    //     completedTasks.push(task);
    //   }
    // })

    const patientData = this.props.patientData && this.props.patientData[0];


    const upcomingTasks = this.state.tasks
      ? this.state.tasks.upcomingTasks
      : [];
    const completedSurveys = this.state.tasks
      ? this.state.tasks.completedSurveys
      : [];
    const upcomingProgress = this.state.tasks
      ? this.state.tasks.upcomingProgress
      : 0;
    const missingProgress = this.state.tasks
      ? this.state.tasks.missingProgress
      : 0;
    const completedProgress = this.state.tasks
      ? this.state.tasks.completedProgress
      : 0;
    const totalWeeksIntoStudy = this.state.tasks
      ? this.state.tasks.totalWeeksIntoStudy
      : 0;

      let week = this.state.tasks && this.state.tasks.totalWeeksIntoStudy;

      let month = this.state.tasks && this.state.tasks.totalWeeksIntoStudy;

      let weekNumber;

      let monthNumber;

      switch (week) {
        case 1:
          weekNumber = 0;
          break;
        case 3:
          weekNumber = 2;
          break;
        case 5:
          weekNumber = 4;
          break;
        case 7:
          weekNumber = 6;
          break;
        case 9:
          weekNumber = 8;
          break;
        default:
          weekNumber = 0; // handle the case when week is not 1, 2, 3, or 4
          break;
      }

      switch (month) {
        case 1:
          monthNumber = 0;
          break;
        case 2:
          monthNumber = 1;
          break;
        case 3:
          monthNumber = 2;
          break;
        case 4:
          monthNumber = 3;
          break;
        case 5:
          monthNumber = 4;
          break;
        case 6:
          monthNumber = 5;
          break;
        case 7:
          monthNumber = 6;
          break;
        default:
          monthNumber = 0; // handle the case when week is not 1, 2, 3, or 4
          break;
      }

      

    return (
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          // trigger={null}
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          // collapsible
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        // collapsed={this.state.collapsed}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
              isPatientProgress={true}
              updateTasks={this.updateTasks}
            />
          </Header>
          <Content className="site-layout-background">
            <Spin spinning={submitted}>
              {!this.state.loading && (
                <Row gutter={16}>
                  <Col xs={24} sm={12} md={6}>
                    <div className="white-box user-section">
                      <div className="user-profile">
                        <Link to={"/patient-progress/" + this.props.match.params.id} >
                          <Avatar size={84}>
                            {this.props.patientData && this.props.patientData[0].firstName
                              .toUpperCase()
                              .charAt(0) 
                              // +
                              // this.props.patientData[0].lastName
                              //   .toUpperCase()
                              //   .charAt(0)
                                }
                          </Avatar>
                        </Link>
                        <div className="profile-details">
                          <Link to={"/patient-progress/" + this.props.match.params.id} >
                            <h3>
                              {this.props.patientData && this.props.patientData[0].firstName 
                              // +
                              //   " " +
                              //   this.props.patientData[0].lastName
                                }
                            </h3>
                          </Link>
                          {
                            studyId === "1" ?
                            <button
                              className="btn"
                              onClick={() => this.goToChat()}
                            >
                              Chat Now
                            </button> : ""
                          }
                          
                        </div>
                      </div>
                      <div className="user-details">
                        <List
                          style={{ marginTop: "40px" }}
                          itemLayout="horizontal"
                          dataSource={this.props.patientData}
                          renderItem={(patient) => (
                            <>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Study:
                                </Typography.Text>{" "}
                                {patient && patient.title}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Email:
                                </Typography.Text>{" "}
                                {patient.email}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Date of Birth:
                                </Typography.Text>{" "}
                                {/* {patient.dateOfBirth
                                  ? moment(new Date(Number(patient.dateOfBirth))).format(
                                    "DD-MM-YYYY"
                                  )
                                  : "N/A"} */}
                                  {finalDate}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Age:
                                </Typography.Text>{" "}
                                {patient.age ? patient.age + " years" : "N/A"}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Phone Number:
                                </Typography.Text>{" "}
                                {"(" +
                                  patient.phoneCode +
                                  ") " +
                                  patient.phoneNumber}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Member Status:
                                </Typography.Text>{" "}
                                {patient.userScreeningStatus.replace("_", " ")}
                              </List.Item>
                              {
                                studyId == 2 ?
                                <List.Item>
                                  <Typography.Text style={{ color: "#006297" }}>
                                    Patient's TimeZone:
                                  </Typography.Text>{" "}
                                    {patient.notificationTimezone}
                                </List.Item>
                                : ""
                              }
                              {
                                studyId == 2 ?
                                <List.Item>
                                  <Typography.Text style={{ color: "#006297" }}>
                                    Notifications Time:
                                  </Typography.Text>{" "}
                                    {formattedTimeStartData} {!formattedTimeStartData && !formattedTimeEndData ? "" : "to"} {formattedTimeEndData}
                                </List.Item>
                                : 
                                ""
                              }
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Registered Date:
                                </Typography.Text>{" "}
                                {patient.createdDate
                                  ? moment(patient.createdDate)
                                    .format(dateFormathh)
                                  : "N/A"}
                              </List.Item>
                            </>
                          )}
                        />
                      </div>
                      <br />
                      <Button
                        className="btn"
                        onClick={(completedTasks.length > 0 || completedSurveys > 0) ? () => this.downloadPatientData() : () => this.info()}
                        // disabled={(completedTasks.length > 0 || completedSurveys > 0) ? false : true}
                      >
                        Download Patient data
                      </Button>
                    </div>
                  </Col>

                  <Col xs={24} sm={12} md={18}>
                    <div className="white-box">
                      <div className="title">
                        <h2>
                          Study Progress {
                            studyId == "2" ?
                            <span className="title-week-count">
                              Week {weekNumber} of 8
                            </span> :  studyId == "4" ?
                            <span className="title-week-count">
                              Month {monthNumber} of 6
                            </span> :
                            <span className="title-week-count">
                              Week {totalWeeksIntoStudy} of 26
                            </span>
                          }
                          
                          <span className="title-icon">
                            <ProgressIcon />
                          </span>
                        </h2>
                      </div>
                      <div className="progress-bar">
                        <Tooltip title={`${completedProgress}% Completed / ${missingProgress}% Missing /  ${upcomingProgress}% Remaining`} >
                          <Progress
                            percent={completedProgress}
                            successPercent={missingProgress}
                            showInfo={false}
                          />
                        </Tooltip>
                        {/* <div className="progress-category">
                          <span
                            className="completed-status"
                            style={{ width: completedProgress + "%" }}
                          >
                            {" "}
                            {completedProgress != 0
                              ? completedProgress + "% Completed"
                              : ""}{" "}
                          </span>
                          <span
                            className="missing-status"
                            style={{ width: missingProgress + "%" }}
                          >
                            {" "}
                            {missingProgress != 0
                              ? missingProgress + "% missing"
                              : ""}{" "}
                          </span>
                          <span
                            className="remaining-status"
                            style={{ width: upcomingProgress + "%" }}
                          >
                            {" "}
                            {upcomingProgress != 0
                              ? upcomingProgress + "% remaining"
                              : ""}{" "}
                          </span>
                        </div> */}
                      </div>
                    </div>
                    <div className="white-box">
                      <div className="title">
                        <h2>
                          Task
                          <span className="title-icon">
                            <TaskListIcon />

                          </span>
                        </h2>
                      </div>
                      <Row gutter={16} className="taskdetail-list">
                        <Col xs={24} md={12}>
                        <List
                          itemLayout="horizontal"
                          dataSource={task.slice(0, 4)}
                          renderItem={(item, index) => {
                            let weekNumber;
                            switch (item.weekCount) {
                              case 1:
                                weekNumber = 0;
                                break;
                              case 3:
                                weekNumber = 2;
                                break;
                              case 5:
                                weekNumber = 4;
                                break;
                              case 7:
                                weekNumber = 6;
                                break;
                              case 9:
                                weekNumber = 8;
                                break;
                              default:
                                weekNumber = 0; // handle the case when weekCount is not 1, 2, 3, 4, or 5
                                break;
                            }
                            let monthNumber;
                            switch (item.weekCount) {
                              case 1:
                                monthNumber = 0;
                                break;
                              case 2:
                                monthNumber = 1;
                                break;
                              case 3:
                                monthNumber = 2;
                                break;
                              case 4:
                                monthNumber = 3;
                                break;
                              case 5:
                                monthNumber = 4;
                                break;
                              case 6:
                                monthNumber = 5;
                                break;
                              case 7:
                                monthNumber = 6;
                                break;
                              default:
                                weekNumber = 0; // handle the case when weekCount is not 1, 2, 3, 4, or 5
                                break;
                            }

                            return (
                              <List.Item
                                key={index}
                                onClick={item.taskName === "Photographs" || item.taskName === "Voice diary" || item.taskName === "Adverse events" ? undefined : () => this.goToTask(item.taskName, item.taskId)}
                                actions={[<a key="list-loadmore-more"></a>]}
                                style={item.taskName === "Photographs" || item.taskName === "Voice diary" || item.taskName === "Adverse events" ? {cursor: "default"} : {cursor: "pointer"}}
                              >
                                <List.Item.Meta
                                  title={item.taskName}
                                  description={studyId == "2" ? "Week " + weekNumber + " of 8": studyId == "4" ? "Month "+ weekNumber + " of 6" : "Week " + item.weekCount + " of 26"}
                                />
                              </List.Item>
                            );
                          }}
                        />
                        </Col>
                        <Col xs={24} md={12}>
                          <List
                            style={{ height: '220px', overflowY: 'scroll' }}
                            itemLayout="horizontal"
                            dataSource={task.slice(4)}
                            renderItem={(item, index) => {
                              let weekNumber;
                              if (studyId === "2"){
                                switch (item.weekCount) {
                                  case 1:
                                    weekNumber = 0;
                                    break;
                                  case 3:
                                    weekNumber = 2;
                                    break;
                                  case 5:
                                    weekNumber = 4;
                                    break;
                                  case 7:
                                    weekNumber = 6;
                                    break;
                                  case 9:
                                    weekNumber = 8;
                                    break;
                                  default:
                                    weekNumber = 0; // handle the case when weekCount is not 1, 2, 3, 4, or 5
                                    break;
                                }
                              }
                              let monthNumber;

                              if (studyId === "4") {
                                switch (item.weekCount) {
                                  case 1:
                                    monthNumber = 0;
                                    break;
                                  case 2:
                                    monthNumber = 1;
                                    break;
                                  case 3:
                                    monthNumber = 2;
                                    break;
                                  case 4:
                                    monthNumber = 3;
                                    break;
                                  case 5:
                                    monthNumber = 4;
                                    break;
                                  case 6:
                                    monthNumber = 5;
                                    break;
                                  case 7:
                                    monthNumber = 6;
                                    break;
                                  default:
                                    weekNumber = 0; // handle the case when weekCount is not 1, 2, 3, 4, or 5
                                    break;
                                }
                              }
  
                              return (
                                <List.Item
                                  key={index}
                                  onClick={item.taskName === "Photographs" || item.taskName === "Voice diary" || item.taskName === "Adverse events" ? undefined : () => this.goToTask(item.taskName, item.taskId)}
                                  actions={[<a key="list-loadmore-more"></a>]}
                                  style={item.taskName === "Photographs" || item.taskName === "Voice diary" || item.taskName === "Adverse events" ? {cursor: "default"} : {cursor: "pointer"}}
                                >
                                  <List.Item.Meta
                                    title={item.taskName}
                                    description={studyId === "2" ? "Week " + weekNumber + " of 8": studyId === "4" ? "Month "+ monthNumber + " of 6" : "Week " + item.weekCount + " of 26"}
                                  />
                                </List.Item>
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className="white-box">
                      <div className="title">
                        <h2>
                          Patient Study Overview
                          <span className="title-icon">
                            <StudyOverviewIcon />
                          </span>
                        </h2>
                      </div>
                      <Row gutter={16} className="taskdetail-list">
                        <Col xs={24} md={12} xxl={6}>
                          <div className="task-overview overdue-task">
                            <h3>Overdue Task</h3>
                            <List
                              itemLayout="horizontal"
                              dataSource={overduetasks}
                              renderItem={(item) => (
                                <List.Item
                                  actions={[
                                    <a key="list-loadmore-more">
                                      <Progress
                                        type="circle"
                                        percent={item.taskPercentage}
                                        width={20}
                                      />
                                    </a>,
                                  ]}
                                >
                                  <List.Item.Meta
                                    title={
                                      item.taskName +
                                      " " +
                                      moment(item.startDate).format(
                                        "MM/DD/YYYY"
                                      )
                                    }
                                    description={"Overdue"}
                                  />
                                </List.Item>
                              )}
                            />
                          </div>
                        </Col>
                        <Col xs={24} md={12} xxl={6}>
                          <div className="task-overview current-task">
                            <h3>Current Task</h3>
                            <List
                              itemLayout="horizontal"
                              dataSource={currentTasks}
                              renderItem={(item) => (
                                <List.Item
                                  actions={[
                                    <a key="list-loadmore-more">
                                      {" "}
                                      <Progress
                                        type="circle"
                                        percent={item.taskPercentage}
                                        width={20}
                                      />
                                    </a>,
                                  ]}
                                >
                                  <List.Item.Meta
                                    title={
                                      item.taskName +
                                      " " +
                                      moment(item.startDate).format(
                                        "MM/DD/YYYY"
                                      )
                                    }
                                    description={"Current"}
                                  />
                                </List.Item>
                              )}
                            />
                          </div>
                        </Col>
                        <Col xs={24} md={12} xxl={6}>
                          <div className="task-overview upcoming-task">
                            <h3>Upcoming Task</h3>
                            <List
                              itemLayout="horizontal"
                              dataSource={upcomingTasks}
                              renderItem={(item) => (
                                <List.Item>
                                  <List.Item.Meta
                                    title={
                                      item.taskName +
                                      " " +
                                      moment(item.startDate).format(
                                        "MM/DD/YYYY"
                                      )
                                    }
                                    description={"Upcoming"}
                                  />
                                </List.Item>
                              )}
                            />
                          </div>
                        </Col>
                        <Col xs={24} md={12} xxl={6}>
                          <div className="task-overview completed-task">
                            <h3>Completed Tasks</h3>
                            <List
                              itemLayout="horizontal"
                              dataSource={completedTasks}
                              renderItem={(item) => (
                                <List.Item>
                                  <List.Item.Meta
                                    title={
                                      item.taskName +
                                      " " +
                                      moment(item.startDate).format(
                                        "MM/DD/YYYY"
                                      )
                                    }
                                    description={"Completed"}
                                  />
                                </List.Item>
                              )}
                            />
                          </div>
                        </Col>
                        <Col xs={24} md={12} xxl={6}>
                          <div className="task-overview completed-task">
                            <h3>Completed Surveys</h3>
                            <List
                              itemLayout="horizontal"
                              dataSource={completedSurveys}
                              renderItem={(item) => (
                                <List.Item
                                  onClick={() => this.goToTask(item.surveyName, item.surveyTrueId)}
                                  actions={[<a key="list-loadmore-more"></a>]}
                                >
                                  <List.Item.Meta
                                    title={
                                      item.surveyName +
                                      " " +
                                      moment(item.startDate).format(
                                        "MM/DD/YYYY"
                                      )
                                    }
                                    description={"Completed"}
                                  />
                                </List.Item>
                              )}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <ul className="link-list-box" style={{ display: "block" }}>
                      {
                        studyId == "2" || studyId == "4"?
                        null :
                        <li>
                          <div onClick={() => this.goToChat()} class="link-box">
                            <ChatIcon />
                            <h4>Chat</h4>
                          </div>
                        </li>
                      }
                     
                      <li>
                        <div
                          onClick={() => this.goToEASiScoring()}
                          class="link-box"
                        >
                          <ServeyIcon />
                          <h4>Surveys &amp; Scores</h4>
                        </div>
                      </li>
                      <li>
                        <div onClick={() => this.goToDiary()} class="link-box">
                          <DairyIcon />
                          <h4>Diary</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToPhotoGallery("gallery")}
                          className={
                            selectedCol === "gallery"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <PhotogalleryIcon />
                          <h4>Photo Gallery </h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToAdverseEvents("adverse")}
                          className={
                            selectedCol === "adverse"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <AdverseEventIcon />
                          <h4>Adverse Events</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToActivityData("activity")}
                          class="link-box"
                        >
                          <DataIcon />
                          <h4>Activity Data</h4>
                        </div>
                      </li>
                      {
                        studyId == "2" || studyId == "4"?
                        null :
                        <li>
                          <div
                            onClick={() => this.goToStudyDrugLog("log")}
                            class="link-box"
                          >
                            <StudyDrugLogIcon />
                            <h4>Study Drug Log</h4>
                          </div>
                        </li>
                      }
                      {
                        studyId == "2" || studyId == "4" ?
                        null :
                        <li>
                          <div
                            onClick={() => this.goToLab("lab")}
                            class="link-box"
                          >
                            <LabIcon />
                            <h4>Lab</h4>
                          </div>
                        </li>
                      }
                      {
                        studyId == 4 && (patientData && patientData.healthCondition !== "HEALTHY_CONTROL") ?
                        <li>
                          <div
                            onClick={() => this.goToFlares()}
                            class="link-box"
                          >
                            <AuditOutlined style={{fontSize: "35px"}}/>
                            <h4>Flares</h4>
                          </div>
                        </li>
                        : ""
                      }
                      
                    </ul>
                  </Col>
                </Row>
              )}
            </Spin>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    tasks: state.task.listTasks,
    patientData: state.patient.patientData,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PatientActions, ...TaskActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    ViewPatientProgress
  )
);
