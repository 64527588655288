import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../layout/sidebar/sidebar.jsx";
import UserHeader from "../layout/header.jsx";
import options from "../layout/sidebar/dashboard-options";
import * as PatientActions from "../../redux/actions/patient-actions";
import * as StudyActions from "../../redux/actions/study-actions";

import * as EasiScoreActions from "../../redux/actions/easi-scores-actions";
import "react-h5-audio-player/lib/styles.css";

import {
  Layout,
  Row,
  Col,
  Avatar,
  Typography,
  List,
  Spin,
  Card,
  Button,
  Radio,
  PageHeader,
} from "antd";
import moment from "moment-timezone";
import { notifyUser } from "../../services/notification-service";
import firebase from "../../../config/firebase";
import Config from "../../config";
import { ChatSvg } from "../shared/svg/chatlg";
import { ServeySvg } from "../shared/svg/servey";
import { DataSvg } from "../shared/svg/dataicon";
import { DairySvg } from "../shared/svg/dairyicon";
import { AdverseEventSvg } from "../shared/svg/adverseevents";
import { PhotogallerySvg } from "../shared/svg/photogallery";
import Icon from "@ant-design/icons";
import { LabSvg } from "../shared/svg/labicon";
import { AuditOutlined } from "@ant-design/icons";

const LabIcon = (props) => <Icon component={LabSvg} {...props} />;
const dateFormathh = "MM/DD/YYYY";
const { Header, Sider, Content } = Layout;
const ChatIcon = (props) => <Icon component={ChatSvg} {...props} />;
const ServeyIcon = (props) => <Icon component={ServeySvg} {...props} />;
const DataIcon = (props) => <Icon component={DataSvg} {...props} />;
const DairyIcon = (props) => <Icon component={DairySvg} {...props} />;
const AdverseEventIcon = (props) => (
  <Icon component={AdverseEventSvg} {...props} />
);
const PhotogalleryIcon = (props) => (
  <Icon component={PhotogallerySvg} {...props} />
);
const ButtonGroup = Button.Group;

class GlobalAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      patientData: undefined,
      loading: true,
      submitted: false,
      patientVoiceDiary: [],
      selectedBodyPart: "globalAss",
      selectItemId: "",
      selectedCol: "",
      selectedMonth: 1,
      selectedMonthNih: 1,
      selectedWeek: 1,
      globalAssessment: undefined,
      month: 1,
      week: 1,
      monthNih: 1,
      range: "",
    };
  }

  async componentDidMount() {
    let studyId = localStorage.getItem("studyId");
    this.props
      .getStudyById(studyId)
      .then((resp) => {
        this.setState({
          //loading: false,
          data: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    let patientId = this.props.match.params.id;
    let month = this.state.month;
    let week = this.state.week;
    let monthNih = this.state.monthNih;
    // await this.props.getPatientById(patientId);
    if (studyId == 2) {
      await this.props.getPatientByIdJanssen(patientId);
    } else if (studyId == 4){
      await this.props.getPatientByIdNih(patientId);
    } else {
      await this.props.getPatientById(patientId);
    }
    if (
      this.props.patientData &&
      this.props.patientData.length > 0 &&
      this.props.patientData[0]
    ) {
      //this.setState({ loading: false })
    }
    if (studyId == 1) {
      await this.props.getGlobalAssessment(month, patientId);
    } else if (studyId === "4"){
      if (this.props.weekByParts && this.props.weekByParts.patId &&
        this.props.weekByParts.patId === patientId
      ) {
        await this.getPhotosNih(this.props.weekByParts.curMonth);
        } else {
          await this.props.getGlobalAssessmentForNih(monthNih, patientId);
        }
    } else {
        if (this.props.weekByParts && this.props.weekByParts != undefined) {
          await this.getPhotosByWeek(this.props.weekByParts);
        } else {
          await this.props.getGlobalAssessmentForJanssen(week, patientId);
        }
    }
    if (this.props.globalAssessment) {
      this.setState({
        globalAssessment: this.props.globalAssessment,
        loading: false
      });
    } else if (this.props.patientDataError) {
      notifyUser(this.props.patientDataError, "error");
      this.props.history.push("/patients/1");
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (this.props.globalAssessment !== nextProps.globalAssessment) {
      this.setState({ globalAssessment: nextProps.globalAssessment });
      this.setState({ loading: false });
    }
  }

  toggleSidebar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  goToChat = () => {
    var patientId = this.props.match.params.id;
    var ref = firebase.firestore().collection("chatrooms");
    var type = Config.server.type;
    ref
      .where(`users.${patientId + type}`, "==", true)
      .get()
      .then((docs) => {
        let room = {};
        docs.forEach((snapshot) => {
          room = snapshot.data();
          room.id = snapshot.id;
        });
        this.props.history.push("/chat/" + patientId);
      });
  };

  goToDiary = (colId) => {
    this.setState({ selectedCol: colId });
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-diary/" + patientId);
  };

  goToPhotoGallery = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/photo-gallery/" + patientId);
  };
  goToPatientProgress = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-progress/" + patientId);
  };

  goToLowerExt = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-lowerExt/" + patientId);
  };
  goToUpperExt = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-upperExt/" + patientId);
  };
  goToTrunk = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-trunk/" + patientId);
  };

  goToReviewTotal = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-total/" + patientId);
  };

  goToGlobalAssessment = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/globalAssessment/" + patientId);
  };

  goToActivityData = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/activity-data/" + patientId);
  };

  goToAdverseEvents = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/adverse-events/" + patientId);
  };

  goToLab = (id) => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/lab/" + patientId);
  };

  goToEASiScoring = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-head/" + patientId);
  };

  async getPhotos(month) {
    var patientId = this.props.match.params.id;
    this.setState({ selectedMonth: month, month: month, loading: true });

    await this.props.getGlobalAssessment(month, patientId);
    if (this.props.globalAssessment) {
      this.setState({
        globalAssessment: this.props.globalAssessment,
        loading: false,
      });
    }
  }

  async getPhotosByWeek(week) {
    var patientId = this.props.match.params.id;
    this.setState({ selectedWeek: week, week: week, loading: true });

    await this.props.getGlobalAssessmentForJanssen(week, patientId);
    if (this.props.globalAssessment) {
      await this.props.getLocalState(week);
      this.setState({
        globalAssessment: this.props.globalAssessment,
        loading: false,
      });
    }
  }

  async getPhotosNih(monthNih) {
    var patientId = this.props.match.params.id;
    this.setState({ selectedMonthNih: monthNih, monthNih: monthNih, loading: true });

    await this.props.getGlobalAssessmentForNih(monthNih, patientId);
    if (this.props.globalAssessment) {
      let data = {
        patId: patientId,
        curMonth: monthNih
      }
      await this.props.getLocalState(data);
      this.setState({
        globalAssessment: this.props.globalAssessment,
        loading: false,
      });
    }
  }

  updateRange = async (range) => {
    this.setState({ range: range });
  };

  addGlobalAssessment = async () => {
    let studyId = localStorage.getItem("studyId");
    var patientId = this.props.match.params.id;
    let month = this.state.month;
    let monthNih = this.state.monthNih;
    let week = this.state.week;
    this.setState({ submitted: true });
    let data = {
      ...(studyId == 1 && {
          patientId: patientId,
          rangeMaxVal: this.state.range,
          month: month,
          studyId: studyId
      }),
      ...(studyId == 2 && {
          patientId: patientId,
          month: month,
          rangeMaxVal: this.state.range,
          week: week,
          studyId: studyId
      }),
      ...(studyId == 4 && {
        patientId: patientId,
        month: monthNih,
        rangeMaxVal: this.state.range,
        studyId: studyId
    })
    }
    // let data = {
    //   patientId: patientId,
    //   rangeMaxVal: this.state.range,
    //   month: month,
    //   studyId: studyId
    // };
    if (studyId === "4") {
      if (this.state.range === "") {
        notifyUser("Please select a score", "error");
        this.setState({ submitted: false });
      } else {
        await this.props.saveGlobalAssessment(data);
        notifyUser("Patient's global assessment updated successfully", "success");
        this.setState({ submitted: false });
      }
    } else {
      await this.props.saveGlobalAssessment(data);
      if (this.props.saveGlobalAssessment) {
        notifyUser("Patient's global assessment updated successfully", "success");
        this.setState({ submitted: false });
      }
    }
  };

  goToFlares = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/flares/" + patientId);
  }

  render() {
    const {
      submitted,
      selectedCol,
      selectedMonth,
      selectedWeek,
      loading,
      globalAssessment,
      selectedBodyPart,
      selectedMonthNih
    } = this.state;
    const studyId = localStorage.getItem("studyId");

    const oldDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].dateOfBirth
    const dateType = !isNaN(Date.parse(oldDate))
    let finalDate = "";
    if (oldDate && dateType == true) {
      finalDate = moment(oldDate).format("MM/DD/YYYY")
    } else if (oldDate && dateType == false) {
      finalDate = moment(new Date(Number(oldDate))).format("MM/DD/YYYY")
    } else {
      finalDate = "N/A"
    }

    const patientDataHealth = this.props.patientData && this.props.patientData[0];

    // Patient Start End time
    const notificationEndDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate;
    const notificationTimezone = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationTimezone;
    const endDateInTimezone = moment.tz(notificationEndDate, notificationTimezone);
    let formattedTimeEnd = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate) {
      formattedTimeEnd = endDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeEnd = "";
    }
    const formattedTimeEndData = formattedTimeEnd;

    const notificationStartDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate;
    const startDateInTimezone = moment.tz(notificationStartDate, notificationTimezone);
    let formattedTimeStart = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate) {
      formattedTimeStart = startDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeStart = "";
    }
    const formattedTimeStartData = formattedTimeStart;
    // Patient Start End time end

    return (
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          // trigger={null}
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          // collapsible
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        // collapsed={this.state.collapsed}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background">
            <PageHeader
              className="site-header-title"
              title={
                this.state.data &&
                  this.state.data.title
              }
            ></PageHeader>
            <Spin spinning={loading}>
              {!this.state.loading && (
                <Row gutter={16}>
                  <Col xs={24} sm={12} md={6} xl={6}>
                    <div className="white-box user-section">
                      <div className="user-profile">
                        <Link to={"/patient-progress/" + this.props.match.params.id} >
                          <Avatar size={84}>
                            {this.props.patientData &&
                              this.props.patientData[0].firstName
                                .toUpperCase()
                                .charAt(0) +
                              this.props.patientData[0].lastName
                                .toUpperCase()
                                .charAt(0)}
                          </Avatar>
                        </Link>
                        <div className="profile-details">
                          <Link to={"/patient-progress/" + this.props.match.params.id} >
                            <h3>
                              {this.props.patientData &&
                                this.props.patientData[0].firstName +
                                " " +
                                this.props.patientData[0].lastName}
                            </h3>
                          </Link>
                          {
                            studyId == 1 ?
                            <button
                              className="btn"
                              onClick={() => this.goToChat()}
                            >
                              Chat Now
                            </button>
                            :
                            ""
                          }
                        </div>
                      </div>
                      <div className="user-details">
                        <List
                          style={{ marginTop: "40px" }}
                          itemLayout="horizontal"
                          dataSource={this.props.patientData}
                          renderItem={(patient) => (
                            <>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Study:
                                </Typography.Text>{" "}
                                {patient && patient.title}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Email:
                                </Typography.Text>{" "}
                                {patient.email}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Date of Birth:
                                </Typography.Text>{" "}
                                {/* {patient.dateOfBirth
                                  ? moment(new Date(Number(patient.dateOfBirth))).format(
                                    "DD-MM-YYYY"
                                  )
                                  : "N/A"} */}
                                  {finalDate}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Age:
                                </Typography.Text>{" "}
                                {patient.age ? patient.age + " years" : "N/A"}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Phone Number:
                                </Typography.Text>{" "}
                                {"(" +
                                  patient.phoneCode +
                                  ") " +
                                  patient.phoneNumber}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Member Status:
                                </Typography.Text>{" "}
                                {patient.userScreeningStatus.replace("_", " ")}
                              </List.Item>
                              {
                                studyId == 2 ?
                                <List.Item>
                                  <Typography.Text style={{ color: "#006297" }}>
                                    Patient's TimeZone:
                                  </Typography.Text>{" "}
                                    {patient.notificationTimezone}
                                </List.Item>
                                : ""
                              }
                              {
                                studyId == 2 ?
                                <List.Item>
                                  <Typography.Text style={{ color: "#006297" }}>
                                    Notifications Time:
                                  </Typography.Text>{" "}
                                    {formattedTimeStartData} {!formattedTimeStartData && !formattedTimeEndData ? "" : "to"} {formattedTimeEndData}
                                </List.Item>
                                : 
                                ""
                              }
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Registered Date:
                                </Typography.Text>{" "}
                                {patient.createdDate
                                  ? moment(patient.createdDate)
                                    .format(dateFormathh)
                                  : "N/A"}
                              </List.Item>
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={18} xl={18}>
                    {
                      studyId == 1 ?
                      <ButtonGroup className="horizontal-button-tabs">
                        <Button
                          className={selectedMonth === 1 ? "active" : ""}
                          onClick={() => this.getPhotos(1)}
                        >
                          Month 1
                        </Button>
                        <Button
                          className={selectedMonth === 2 ? "active" : ""}
                          onClick={() => this.getPhotos(2)}
                        >
                          Month 2
                        </Button>
                        <Button
                          className={selectedMonth === 3 ? "active" : ""}
                          onClick={() => this.getPhotos(3)}
                        >
                          Month 3
                        </Button>
                        <Button
                          className={selectedMonth === 4 ? "active" : ""}
                          onClick={() => this.getPhotos(4)}
                        >
                          Month 4
                        </Button>
                        <Button
                          className={selectedMonth === 5 ? "active" : ""}
                          onCldefaultExcoriationick={() => this.getPhotos(5)}
                        >
                          Month 5
                        </Button>
                        <Button
                          className={selectedMonth === 6 ? "active" : ""}
                          onClick={() => this.getPhotos(6)}
                        >
                          Month 6
                        </Button>
                      </ButtonGroup>
                      : studyId == 4 ?
                      <ButtonGroup className="horizontal-button-tabs spaces">
                        <Button
                          className={selectedMonthNih === 1 ? "active" : ""}
                          onClick={() => this.getPhotosNih(1)}
                        >
                          Month 0
                        </Button>
                        <Button
                          className={selectedMonthNih === 2 ? "active" : ""}
                          onClick={() => this.getPhotosNih(2)}
                        >
                          Month 1
                        </Button>
                        <Button
                          className={selectedMonthNih === 3 ? "active" : ""}
                          onClick={() => this.getPhotosNih(3)}
                        >
                          Month 2
                        </Button>
                        <Button
                          className={selectedMonthNih === 4 ? "active" : ""}
                          onClick={() => this.getPhotosNih(4)}
                        >
                          Month 3
                        </Button>
                        <Button
                          className={selectedMonthNih === 5 ? "active" : ""}
                          onClick={() => this.getPhotosNih(5)}
                        >
                          Month 4
                        </Button>
                        <Button
                          className={selectedMonthNih === 6 ? "active" : ""}
                          onClick={() => this.getPhotosNih(6)}
                        >
                          Month 5
                        </Button>
                        <Button
                          className={selectedMonthNih === 7 ? "active" : ""}
                          onClick={() => this.getPhotosNih(7)}
                        >
                          Month 6
                        </Button>
                      </ButtonGroup>
                      : 
                      <ButtonGroup className="horizontal-button-tabs spaces">
                        <Button
                          className={selectedWeek === 1 ? "active" : ""}
                          onClick={() => this.getPhotosByWeek(1)}
                        >
                          Week 0
                        </Button>  
                        <Button
                          className={selectedWeek === 3 ? "active" : ""}
                          onClick={() => this.getPhotosByWeek(3)}
                        >
                          Week 2
                        </Button>  
                        <Button
                          className={selectedWeek === 5 ? "active" : ""}
                          onClick={() => this.getPhotosByWeek(5)}
                        >
                          Week 4
                        </Button>  
                        <Button
                          className={selectedWeek === 7 ? "active" : ""}
                          onClick={() => this.getPhotosByWeek(7)}
                        >
                          Week 6
                        </Button>  
                        <Button
                          className={selectedWeek === 9 ? "active" : ""}
                          onClick={() => this.getPhotosByWeek(9)}
                        >
                          Week 8
                        </Button> 
                      </ButtonGroup>
                    }
                    <ButtonGroup className="horizontal-button-tabs spaces">
                      <Button onClick={() => this.goToEASiScoring()}>
                        Head
                      </Button>
                      <Button onClick={() => this.goToUpperExt()}>
                        {studyId == 4 ? "Upper Limbs" : "Upper Extremity"}
                      </Button>
                      <Button onClick={() => this.goToLowerExt()}>
                        {studyId == 4 ? "Lower Limbs" : "Lower Extremity"}
                      </Button>
                      <Button onClick={() => this.goToTrunk()}>Trunk</Button>
                      <Button onClick={() => this.goToReviewTotal()}>
                        Review Total Scores
                      </Button>
                      <Button
                        className={
                          selectedBodyPart === "globalAss" ? "active" : ""
                        }
                        onClick={() => this.goToGlobalAssessment()}
                      >
                        Global Assessment
                      </Button>
                    </ButtonGroup>
                    <Card
                      title={"Patient Data"}
                      extra={
                        <Button onClick={() => this.goToPatientProgress()}>
                          Back
                        </Button>
                      }
                      style={{ marginBottom: "8px", padding: "0 16px" }}
                      className="patient-dairy-section"
                    >
                      <Row gutter={0}>
                        <Col xs={24} sm={24} md={24} xl={24}>
                          {/* Activity Content Section Start */}
                          <div className="patient-data-section">
                            <div className="play-icon-container"></div>
                            <Spin spinning={submitted}>
                              {globalAssessment ? (
                                <div className="review-data-section">
                                  <Row gutter={16} className="data-header-row">
                                    <Col xs={24} md={8} xl={6}>
                                      Global Assessment
                                    </Col>
                                    <Col xs={24} md={16} xl={18}>
                                      <div className="value-header">
                                        <div className="value-header-col">
                                          Clear (0)
                                        </div>
                                        <div className="value-header-col">
                                          Almost Clear (1)
                                        </div>
                                        <div className="value-header-col">
                                          Mild (2)
                                        </div>
                                        <div className="value-header-col">
                                          Moderate (3)
                                        </div>
                                        <div className="value-header-col">
                                          Severe (4)
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row gutter={16} className="data-content-row">
                                    <Col xs={24} md={8} xl={6}>
                                      Please select a score
                                    </Col>
                                    <Col xs={24} md={16} xl={18}>
                                      <Radio.Group
                                        defaultValue={
                                          globalAssessment &&
                                            globalAssessment.rangeMaxVal
                                            ? globalAssessment.rangeMaxVal
                                            : "5"
                                        }
                                        className="review-value-selection"
                                      >
                                        <Radio
                                          onClick={() => this.updateRange("0")}
                                          key="ER1"
                                          value={"0"}
                                        />
                                        <Radio
                                          onClick={() => this.updateRange("1")}
                                          key="ER2"
                                          value={"1"}
                                        />
                                        <Radio
                                          onClick={() => this.updateRange("2")}
                                          key="ER3"
                                          value={"2"}
                                        />
                                        <Radio
                                          onClick={() => this.updateRange("3")}
                                          key="ER4"
                                          value={"3"}
                                        />
                                        <Radio
                                          onClick={() => this.updateRange("4")}
                                          key="ER5"
                                          value={"4"}
                                        />
                                      </Radio.Group>
                                    </Col>
                                  </Row>

                                  <Row gutter={16} className="data-content-row">
                                    <Col xs={24} md={8}>
                                      <Button
                                        onClick={() =>
                                          this.addGlobalAssessment()
                                        }
                                        type="primary"
                                        className="btn-sq"
                                      >
                                        Submit
                                      </Button>
                                    </Col>
                                    <Col xs={24} md={16}>
                                      {" "}
                                      <Button
                                        onClick={() => this.goToPhotoGallery()}
                                        type="primary"
                                        className="btn-sq"
                                        style={{ marginBottom: 30 }}
                                      >
                                        Review All Photos
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              ) : (
                                ""
                              )}
                            </Spin>
                          </div>

                          {/* /Activity Content Section End */}
                        </Col>
                      </Row>
                    </Card>

                    <ul className="link-list-box" style={{ display: "block" }}>
                      {
                        studyId == 1 ?
                        <li>
                          <div onClick={() => this.goToChat()} class="link-box">
                            <ChatIcon />
                            <h4>Chat</h4>
                          </div>
                        </li>
                        :
                        ""
                      }
                      <li>
                        <div
                          onClick={() => this.goToEASiScoring()}
                          class="link-box"
                        >
                          <ServeyIcon />
                          <h4>Surveys &amp; Scores</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToDiary("diary")}
                          className={
                            selectedCol === "diary"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <DairyIcon />
                          <h4>Diary</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToPhotoGallery("gallery")}
                          className={
                            selectedCol === "gallery"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <PhotogalleryIcon />
                          <h4>Photo Gallery </h4>
                        </div>
                      </li>
                      <li>
                        <div
                          className={
                            selectedCol === "adverseEvents"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                          onClick={() => this.goToAdverseEvents("adverse")}
                        >
                          <AdverseEventIcon />
                          <h4>Adverse Events</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToActivityData("activity")}
                          class="link-box"
                        >
                          <DataIcon />
                          <h4>Activity Data</h4>
                        </div>
                      </li>
                      {
                        studyId == 1 ?
                        <li>
                          <div
                            onClick={() => this.goToLab("lab")}
                            class="link-box"
                          >
                            <LabIcon />
                            <h4>Lab</h4>
                          </div>
                        </li>
                        :
                        ""
                      }
                      {
                        studyId == 4 && (patientDataHealth && patientDataHealth.healthCondition !== "HEALTHY_CONTROL") ?
                        <li>
                          <div
                            onClick={() => this.goToFlares()}
                            class="link-box"
                          >
                            <AuditOutlined style={{fontSize: "35px"}}/>
                            <h4>Flares</h4>
                          </div>
                        </li>
                        : ""
                      }
                    </ul>
                  </Col>
                </Row>
              )}
            </Spin>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientData: state.patient.patientData,
    globalAssessment: state.easiScore.globalAssessment,
    weekByParts: state.patient.weekByParts
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...PatientActions, ...StudyActions, ...EasiScoreActions },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    GlobalAssessment
  )
);
