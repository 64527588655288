import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../layout/sidebar/sidebar.jsx";
import UserHeader from "../layout/header.jsx";
import options from "../layout/sidebar/dashboard-options";
import * as PatientActions from "../../redux/actions/patient-actions";
import * as StudyActions from "../../redux/actions/study-actions";
import SleepMeasures from "./sleepMeasures";
import DailySteps from "./dailySteps";
import Exercise from "./exercise";
import StandHours from "./standHours";
import MoveHours from "./moveHours";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";

import * as ActivityActions from "../../redux/actions/patient-activity-actions";
import "react-h5-audio-player/lib/styles.css";
import {
  Layout,
  Row,
  Col,
  Avatar,
  Typography,
  List,
  Spin,
  Card,
  Button,
  Tabs,
  PageHeader,
} from "antd";
import moment from "moment-timezone";
import { notifyUser } from "../../services/notification-service";
import firebase from "../../../config/firebase";
import Config from "../../config";
import { ChatSvg } from "../shared/svg/chatlg";
import { ServeySvg } from "../shared/svg/servey";
import { DataSvg } from "../shared/svg/dataicon";
import { DairySvg } from "../shared/svg/dairyicon";
import { AdverseEventSvg } from "../shared/svg/adverseevents";
import { LabSvg } from "../shared/svg/labicon";
import { PaymentSvg } from "../shared/svg/paymenticon";
import { PhotogallerySvg } from "../shared/svg/photogallery";
import Icon from "@ant-design/icons";
import { StudyDrugLogSvg } from "../shared/svg/studydruglog.jsx";
import { AuditOutlined } from "@ant-design/icons";

const dateFormathh = "MM/DD/YYYY";
const { Header, Sider, Content } = Layout;
const ChatIcon = (props) => <Icon component={ChatSvg} {...props} />;
const ServeyIcon = (props) => <Icon component={ServeySvg} {...props} />;
const DataIcon = (props) => <Icon component={DataSvg} {...props} />;
const DairyIcon = (props) => <Icon component={DairySvg} {...props} />;
const AdverseEventIcon = (props) => (
  <Icon component={AdverseEventSvg} {...props} />
);
const LabIcon = (props) => <Icon component={LabSvg} {...props} />;
const PaymentIcon = (props) => <Icon component={PaymentSvg} {...props} />;
const PhotogalleryIcon = (props) => (
  <Icon component={PhotogallerySvg} {...props} />
);
const StudyDrugLogIcon = (props) => (
  <Icon component={StudyDrugLogSvg} {...props} />
);

const { TabPane } = Tabs;

class PatientActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      patientData: undefined,
      loading: true,
      submitted: false,
      selectItemId: "",
      selectedCol: "activity",
      evsFactors: undefined,
      patientId: undefined,
      activeKey: "51",
      defaultDate: moment(new Date()).format("yyyy-MM-DD")
    };
  }

  async componentDidMount() {
    let studyId = localStorage.getItem("studyId");
    this.props
      .getStudyById(studyId)
      .then((resp) => {
        this.setState({
          loading: false,
          data: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    let patientId = this.props.match.params.id;
    this.setState({ patientId: patientId });
    if (studyId == 2) {
      await this.props.getPatientByIdJanssen(patientId);
    }else if(studyId == 4) {
      await this.props.getPatientByIdNih(patientId);
    } else {
      await this.props.getPatientById(patientId);
    }
    if (
      this.props.patientData &&
      this.props.patientData.length > 0 &&
      this.props.patientData[0]
    ) {
      this.setState({ loading: false });
      let localStudyId = localStorage.getItem("studyId");
      let patId = this.props.patientData[0].studyId
      if (localStudyId != patId || !patId) {
        this.props.history.push("/dashboard");
        notifyUser("You are not authorized to view this Patient!", "error");
      }
    } else if (this.props.patientDataError) {
      notifyUser(this.props.patientDataError, "error");
      this.props.history.push("/patients/1");
    }
    let evsFactors = await this.props.getEnvironmentFactors(
      moment(new Date()).format("yyyy-MM-DD"),
      patientId
    );
    if (evsFactors) {
      this.setState({ evsFactors: evsFactors.data });
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (this.props.evsFactors !== nextProps.evsFactors) {
      this.setState({ evsFactors: nextProps.evsFactors });
    }
  }

  toggleSidebar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  goToEASiScoring = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-head/" + patientId);
  };

  goToChat = () => {
    var patientId = this.props.match.params.id;
    var ref = firebase.firestore().collection("chatrooms");
    var type = Config.server.type;
    ref
      .where(`users.${patientId + type}`, "==", true)
      .get()
      .then((docs) => {
        let room = {};
        docs.forEach((snapshot) => {
          room = snapshot.data();
          room.id = snapshot.id;
        });
        this.props.history.push("/chat/" + patientId);
      });
  };

  goToDiary = (colId) => {
    this.setState({ selectedCol: colId });
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-diary/" + patientId);
  };

  goToPhotoGallery = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/photo-gallery/" + patientId);
  };
  goToPhotoActivity = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/activity-data/" + patientId);
  };
  goToPatientProgress = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-progress/" + patientId);
  };

  goToAdverseEvents = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/adverse-events/" + patientId);
  };

  async OnChange(args) {
    let date = moment(args.value).format("yyyy-MM-DD");
    let evsFactors =  await this.props.getEnvironmentFactors(date, this.state.patientId);
    if (evsFactors) {
      this.setState({ evsFactors: evsFactors.data, defaultDate: date });
    }
  }

  goToStudyDrugLog = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/study-drug-log/" + patientId);
  };
  
  goToFlares = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/flares/" + patientId);
  }

  onTabChange = (activeKey) => {
    this.setState({ activeKey: activeKey });
  };

  render() {
    const { submitted, selectedCol, evsFactors, defaultDate } = this.state;
    const oldDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].dateOfBirth
    const dateType = !isNaN(Date.parse(oldDate))
    let finalDate = "";
    if (oldDate && dateType == true) {
      finalDate = moment(oldDate).format("MM/DD/YYYY")
    } else if (oldDate && dateType == false) {
      finalDate = moment(new Date(Number(oldDate))).format("MM/DD/YYYY")
    } else {
      finalDate = "N/A"
    }

    const patientData = this.props.patientData && this.props.patientData[0];

    const studyId = localStorage.getItem("studyId");

    // Patient Start End time
    const notificationEndDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate;
    const notificationTimezone = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationTimezone;
    const endDateInTimezone = moment.tz(notificationEndDate, notificationTimezone);
    let formattedTimeEnd = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate) {
      formattedTimeEnd = endDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeEnd = "";
    }
    const formattedTimeEndData = formattedTimeEnd;

    const notificationStartDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate;
    const startDateInTimezone = moment.tz(notificationStartDate, notificationTimezone);
    let formattedTimeStart = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate) {
      formattedTimeStart = startDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeStart = "";
    }
    const formattedTimeStartData = formattedTimeStart;
    // Patient Start End time end

    return (
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          // trigger={null}
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          // collapsible
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        // collapsed={this.state.collapsed}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background">
            {/* <Card
              title={
                this.state.data &&
                this.state.data != null &&
                this.state.data.title
                  ? this.state.data.title
                  : "Eczema Tracking Study"
              }
            ></Card> */}

            <PageHeader
              className="site-header-title"
              title={
                this.state.data &&
                  this.state.data.title 
              }
            />

            <Spin spinning={submitted}>
              {!this.state.loading && (
                <Row gutter={16}>
                  <Col xs={24} sm={12} md={6} xl={6}>
                    <div className="white-box user-section">
                      <div className="user-profile">
                        <Link to={"/patient-progress/" + this.props.match.params.id} >
                          <Avatar size={84}>
                            {this.props.patientData &&
                              this.props.patientData[0].firstName
                                .toUpperCase()
                                .charAt(0) +
                              this.props.patientData[0].lastName
                                .toUpperCase()
                                .charAt(0)}
                          </Avatar>
                        </Link>
                        <div className="profile-details">
                          <Link to={"/patient-progress/" + this.props.match.params.id} >
                            <h3>
                              {this.props.patientData &&
                                this.props.patientData[0].firstName +
                                " " +
                                this.props.patientData[0].lastName}
                            </h3>
                          </Link>
                          {
                            studyId == 1 ?
                            <button
                              className="btn"
                              onClick={() => this.goToChat()}
                            >
                              Chat Now
                            </button>
                            :
                            ""
                          }
                          
                        </div>
                      </div>
                      <div className="user-details">
                        <List
                          style={{ marginTop: "40px" }}
                          itemLayout="horizontal"
                          dataSource={this.props.patientData}
                          renderItem={(patient) => (
                            <>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Study:
                                </Typography.Text>{" "}
                                {patient && patient.title}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Email:
                                </Typography.Text>{" "}
                                {patient.email}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Date of Birth:
                                </Typography.Text>{" "}
                                {/* {patient.dateOfBirth
                                  ? moment(new Date(Number(patient.dateOfBirth))).format(
                                    "DD-MM-YYYY"
                                  )
                                  : "N/A"} */}
                                  {finalDate}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Age:
                                </Typography.Text>{" "}
                                {patient.age ? patient.age + " years" : "N/A"}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Phone Number:
                                </Typography.Text>{" "}
                                {"(" +
                                  patient.phoneCode +
                                  ") " +
                                  patient.phoneNumber}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Member Status:
                                </Typography.Text>{" "}
                                {patient.userScreeningStatus.replace("_", " ")}
                              </List.Item>
                              {
                                studyId == 2 ?
                                <List.Item>
                                  <Typography.Text style={{ color: "#006297" }}>
                                    Patient's TimeZone:
                                  </Typography.Text>{" "}
                                    {patient.notificationTimezone}
                                </List.Item>
                                : ""
                              }
                              {
                                studyId == 2 ?
                                <List.Item>
                                  <Typography.Text style={{ color: "#006297" }}>
                                    Notifications Time:
                                  </Typography.Text>{" "}
                                    {formattedTimeStartData} {!formattedTimeStartData && !formattedTimeEndData ? "" : "to"} {formattedTimeEndData}
                                </List.Item>
                                : 
                                ""
                              }
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Registered Date:
                                </Typography.Text>{" "}
                                {patient.createdDate
                                  ? moment(patient.createdDate)
                                    .format(dateFormathh)
                                  : "N/A"}
                              </List.Item>
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={18} xl={18}>
                    <Card
                      title={"Activity Data"}
                      extra={
                        <Button onClick={() => this.goToPatientProgress()}>
                          Back
                        </Button>
                      }
                      style={{ marginBottom: "8px", padding: "0 16px" }}
                      className="patient-dairy-section"
                    >
                      <Tabs
                        tabPosition="left"
                        type="card"
                        className="activity-data-tabs"
                        onChange={this.onTabChange}
                      >
                        <TabPane tab="Physical Activity" key="1" type="card">
                          <div className="subtabs-section">
                            <Tabs 
                              type="card"
                              onChange={this.onTabChange}
                              >
                              <TabPane tab="Daily Steps" key="51">
                                <DailySteps key={this.state.activeKey}/>
                              </TabPane>
                              <TabPane tab="Exercise Duration" key="52">
                                <Exercise key={this.state.activeKey}/>
                              </TabPane>
                              <TabPane tab="Move Hours" key="53">
                                <MoveHours key={this.state.activeKey}/>
                              </TabPane>
                              <TabPane tab="Stand Hours" key="54">
                                <StandHours key={this.state.activeKey}/>
                              </TabPane>
                            </Tabs>
                          </div>
                        </TabPane>
                        <TabPane tab="Sleep Measures" key="2">
                          <div className="subtabs-section">
                            <Tabs>
                              <TabPane tab="Sleep Measures" key="101">
                                <SleepMeasures key={this.state.activeKey}/>
                              </TabPane>
                            </Tabs>
                          </div>
                        </TabPane>
                        <TabPane tab="Environmental Factors" key="3">
                          <Row gutter={16} className="efactors-section">
                            <Col xs={24} md={24}>
                              <div className="datepicker-control-section">
                                <DatePickerComponent
                                  value={defaultDate}
                                  onChange={(args) => this.OnChange(args)}
                                ></DatePickerComponent>
                              </div>
                            </Col>
                            {evsFactors ? (
                              <Col xs={12} sm={8} md={6} lg={6}>
                                <div class="efactors-box selected">
                                  <span className="count-value">
                                    {evsFactors.mold}
                                  </span>
                                  <h4>Mold</h4>
                                </div>
                              </Col>
                            ) : (
                              <Col xs={12} sm={8} md={6} lg={6}>
                                <div class="efactors-box selected">
                                  <span className="count-value">0</span>
                                  <h4>Mold</h4>
                                </div>
                              </Col>
                            )}
                            {evsFactors ? (
                              <Col xs={12} sm={8} md={6} lg={6}>
                                <div class="efactors-box selected">
                                  <span className="count-value">
                                    {evsFactors.grass}
                                  </span>
                                  <h4>Grass</h4>
                                </div>
                              </Col>
                            ) : (
                              <Col xs={12} sm={8} md={6} lg={6}>
                                <div class="efactors-box selected">
                                  <span className="count-value">0</span>
                                  <h4>Grass</h4>
                                </div>
                              </Col>
                            )}
                            {evsFactors ? (
                              <Col xs={12} sm={8} md={6} lg={6}>
                                <div class="efactors-box selected">
                                  <span className="count-value">
                                    {evsFactors.minTemp +
                                      "to " +
                                      evsFactors.maxTemp}
                                  </span>
                                  <h4>Temperature</h4>
                                </div>
                              </Col>
                            ) : (
                              <Col xs={12} sm={8} md={6} lg={6}>
                                <div class="efactors-box selected">
                                  <span className="count-value">0</span>
                                  <h4>Temperature</h4>
                                </div>
                              </Col>
                            )}
                            {evsFactors ? (
                              <Col xs={12} sm={8} md={6} lg={6}>
                                <div class="efactors-box selected">
                                  <span className="count-value">
                                    {evsFactors.airQuality}
                                  </span>
                                  <h4>AirQuality</h4>
                                </div>
                              </Col>
                            ) : (
                              <Col xs={12} sm={8} md={6} lg={6}>
                                <div class="efactors-box selected">
                                  <span className="count-value">0</span>
                                  <h4>AirQuality</h4>
                                </div>
                              </Col>
                            )}
                            {evsFactors ? (
                              <Col xs={12} sm={8} md={6} lg={6}>
                                <div class="efactors-box selected">
                                  <span className="count-value">
                                    {evsFactors.tree}
                                  </span>
                                  <h4>Tree</h4>
                                </div>
                              </Col>
                            ) : (
                              <Col xs={12} sm={8} md={6} lg={6}>
                                <div class="efactors-box selected">
                                  <span className="count-value">0</span>
                                  <h4>Tree</h4>
                                </div>
                              </Col>
                            )}
                            {evsFactors ? (
                              <Col xs={12} sm={8} md={6} lg={6}>
                                <div class="efactors-box selected">
                                  <span className="count-value">
                                    {evsFactors.uvIndex}
                                  </span>
                                  <h4>UVIndex</h4>
                                </div>
                              </Col>
                            ) : (
                              <Col xs={12} sm={8} md={6} lg={6}>
                                <div class="efactors-box selected">
                                  <span className="count-value">0</span>
                                  <h4>UVIndex</h4>
                                </div>
                              </Col>
                            )}
                            {evsFactors ? (
                              <Col xs={12} sm={8} md={6} lg={6}>
                                <div class="efactors-box selected">
                                  <span className="count-value">
                                    {evsFactors.ragweed}
                                  </span>
                                  <h4>Ragweed</h4>
                                </div>
                              </Col>
                            ) : (
                              <Col xs={12} sm={8} md={6} lg={6}>
                                <div class="efactors-box selected">
                                  <span className="count-value">0</span>
                                  <h4>Ragweed</h4>
                                </div>
                              </Col>
                            )}
                          </Row>
                        </TabPane>
                      </Tabs>
                    </Card>

                    <ul className="link-list-box" style={{ display: "block" }}>
                      {
                        studyId == 1 ?
                        <li>
                          <div onClick={() => this.goToChat()} class="link-box">
                            <ChatIcon />
                            <h4>Chat</h4>
                          </div>
                        </li>
                        :
                        ""
                      }
                      <li>
                        <div
                          onClick={() => this.goToEASiScoring()}
                          class="link-box"
                        >
                          <ServeyIcon />
                          <h4>Surveys &amp; Scores</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToDiary("diary")}
                          className={
                            selectedCol === "diary"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <DairyIcon />
                          <h4>Diary</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          className={
                            selectedCol === "gallery"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <PhotogalleryIcon
                            onClick={() => this.goToPhotoGallery("gallery")}
                          />
                          <h4>Photo Gallery </h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToAdverseEvents("adverse")}
                          className={
                            selectedCol === "adverse"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <AdverseEventIcon />
                          <h4>Adverse Events</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          className={
                            selectedCol === "activity"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                          onClick={() => this.goToPhotoActivity("activity")}
                        >
                          <DataIcon />
                          <h4>Activity Data</h4>
                        </div>
                      </li>
                      {
                        studyId == 1 ?
                        <li>
                          <div
                            onClick={() => this.goToStudyDrugLog("log")}
                            class="link-box"
                          >
                            <StudyDrugLogIcon />
                            <h4>Study Drug Log</h4>
                          </div>
                        </li>
                        :
                        ""
                      }
                      {
                        studyId == 4 && (patientData && patientData.healthCondition !== "HEALTHY_CONTROL") ?
                        <li>
                          <div
                            onClick={() => this.goToFlares()}
                            class="link-box"
                          >
                            <AuditOutlined style={{fontSize: "35px"}}/>
                            <h4>Flares</h4>
                          </div>
                        </li>
                        : ""
                      }
                    </ul>

                    {/* <div className="white-box"> */}
                    {/* <Row gutter={8} className="user-links"> */}
                    {/* <Col xs={12} sm={8} md={6} lg={6} xl={4} xxl={3}>
                            <div onClick={() => this.goToChat()} class="link-box">
                              <ChatIcon />
                              <h4>Chat</h4>
                            </div>
                          </Col>
                          <Col xs={12} sm={8} md={6} lg={6} xl={4} xxl={3}>
                            <div onClick={() => this.goToEASiScoring()} class="link-box">
                              <ServeyIcon />
                              <h4>Surveys &amp; Scores</h4>
                            </div>
                          </Col>

                          <Col xs={12} sm={8} md={6} lg={6} xl={4} xxl={3}>
                            <div onClick={() => this.goToDiary("diary")} className={
                              selectedCol === "diary"
                                ? "link-box selected-item-list"
                                : "link-box"
                            }>
                              <DairyIcon />
                              <h4>Diary</h4>
                            </div>
                          </Col>
                          <Col xs={12} sm={8} md={6} lg={6} xl={4} xxl={3}>
                            <div className={
                              selectedCol === "gallery"
                                ? "link-box selected-item-list"
                                : "link-box"
                            }>
                              <PhotogalleryIcon onClick={() => this.goToPhotoGallery("gallery")} />
                              <h4>Photo Gallery </h4>
                            </div>
                          </Col> */}
                    {/* <Col xs={12} sm={8} md={6} lg={6} xl={4} xxl={3}>
                            <div class="link-box">
                              <LabIcon />
                              <h4>Lab</h4>
                            </div>
                          </Col>*/}
                    {/* <Col xs={12} sm={8} md={6} lg={6} xl={4} xxl={3}>
                            <div onClick={() => this.goToAdverseEvents("adverse")} className={
                              selectedCol === "adverse"
                                ? "link-box selected-item-list"
                                : "link-box"
                            }>
                              <AdverseEventIcon />
                              <h4>Adverse Events</h4>
                            </div>
                          </Col>
                          <Col xs={12} sm={8} md={6} lg={6} xl={4} xxl={3}>
                            <div className={
                              selectedCol === "activity"
                                ? "link-box selected-item-list"
                                : "link-box"
                            }
                              onClick={() => this.goToPhotoActivity("activity")}
                            >
                              <DataIcon />
                              <h4>Activity Data</h4>
                            </div>
                          </Col> */}
                    {/*<Col xs={12} sm={8} md={6} lg={6} xl={4} xxl={3}>
                            <div class="link-box">
                              <PaymentIcon />
                              <h4>Payment</h4>
                            </div>
                          </Col>*/}
                    {/* <Col xs={12} sm={8} md={6} lg={6} xl={4} xxl={3}>
                            <div onClick={() => this.goToStudyDrugLog("log")} class="link-box">
                              <StudyDrugLogIcon />
                              <h4>Study Drug Log</h4>
                            </div>
                          </Col>
                        </Row> */}
                    {/* </div> */}
                  </Col>
                </Row>
              )}
            </Spin>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientData: state.patient.patientData,
    evsFactors: state.activity.getEvsFactors,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...PatientActions, ...ActivityActions, ...StudyActions },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    PatientActivity
  )
);
