import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../layout/sidebar/sidebar.jsx";
import UserHeader from "../layout/header.jsx";
import options from "../layout/sidebar/dashboard-options";
import * as PatientActions from "../../redux/actions/patient-actions";
import * as StudyActions from "../../redux/actions/study-actions";

import * as PatientDiaryActions from "../../redux/actions/patient-diary-actions";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

import {
  Layout,
  Row,
  Col,
  Avatar,
  Typography,
  List,
  Spin,
  Card,
  Button,
  Tabs,
  Modal,
  Form,
  Input,
  Comment,
  Tooltip,
  PageHeader,
} from "antd";
import moment from "moment-timezone";
import { notifyUser } from "../../services/notification-service";
import firebase from "../../../config/firebase";
import Config from "../../config";
import notesPlay from "../../assets/images/play-icon.png";
import soundWaves from "../../assets/images/Waves.png";
import { ChatSvg } from "../shared/svg/chatlg";
import { ServeySvg } from "../shared/svg/servey";
import { DataSvg } from "../shared/svg/dataicon";
import { DairySvg } from "../shared/svg/dairyicon";
import { AdverseEventSvg } from "../shared/svg/adverseevents";
import { PhotogallerySvg } from "../shared/svg/photogallery";
import Icon from "@ant-design/icons";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { PlusCircleFilled, MinusCircleFilled, AuditOutlined } from "@ant-design/icons";
import { StudyDrugLogSvg } from "../shared/svg/studydruglog.jsx";
import { LabSvg } from "../shared/svg/labicon";

const LabIcon = (props) => <Icon component={LabSvg} {...props} />;
const dateFormathh = "MM/DD/YYYY";
const { Header, Sider, Content } = Layout;
const ChatIcon = (props) => <Icon component={ChatSvg} {...props} />;
const ServeyIcon = (props) => <Icon component={ServeySvg} {...props} />;
const DataIcon = (props) => <Icon component={DataSvg} {...props} />;
const DairyIcon = (props) => <Icon component={DairySvg} {...props} />;
const AdverseEventIcon = (props) => (
  <Icon component={AdverseEventSvg} {...props} />
);
const PhotogalleryIcon = (props) => (
  <Icon component={PhotogallerySvg} {...props} />
);
const StudyDrugLogIcon = (props) => (
  <Icon component={StudyDrugLogSvg} {...props} />
);

const { TabPane } = Tabs;
const dateFormat = "Do MMM";

class FlaresDiary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      patientData: undefined,
      loading: true,
      isModalVisible: false,
      submitted: true,
      patientVoiceDiary: [],
      filepath: "",
      diaryTitle: "",
      voiceDiaryId: "",
      textDiaryTitle: "",
      textDiaryId: "",
      diarytext: "",
      formKey: 0,
      selectItemId: "",
      selectedCol: "diary",
      imageUrl: "",
      photoDiaryTitle: "",
      photoDiaryId: "",
      photoLoading: false,
      voiceDiary: [],
      photoDiary: [],
      textDiary: []
    };
  }

  async componentDidMount() {
    let studyId = localStorage.getItem("studyId");
    this.props
      .getStudyById(studyId)
      .then((resp) => {
        this.setState({
          loading: false,
          data: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    let patientId = this.props.match.params.id;
    let date = this.props.match.params.date
    await this.props.getPatientByIdNih(patientId);
    if (
      this.props.patientData &&
      this.props.patientData.length > 0 &&
      this.props.patientData[0]
    ) {
      this.setState({ loading: false });
    } else if (this.props.patientDataError) {
      notifyUser(this.props.patientDataError, "error");
      this.props.history.push("/patients/1");
    }
    let voiceDiary = await this.props.getFlareVoiceDiaries(patientId, date);
    if (voiceDiary && voiceDiary.data && voiceDiary.data.length > 0) {
        this.setState({
          filepath: voiceDiary.data[0].filePath,
          diaryTitle: voiceDiary.data[0].title,
          voiceDiaryId: voiceDiary.data[0].id,
          selectItemId: voiceDiary.data[0].id,
        });
        await this.props.getDoctorCommentsOnVoiceDiary(voiceDiary.data[0].id);
    }
    let photoDiary = await this.props.getPhotoDiaries(patientId);
    let textDiary = await this.props.getTextDiaries(patientId);
    this.setState({voiceDiary: voiceDiary.data, photoDiary: photoDiary.data, textDiary: textDiary.data, submitted: false})
  }

  toggleSidebar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  addDoctorNotesOnVoiceDiary = async (values) => {
    this.setState({ formKey: (this.state.formKey || 0) + 1 });

    let self = this;
    if (self.state.voiceDiaryId === "" || self.state.voiceDiaryId === null) {
      notifyUser("Please select any diary", "error");
    } else {
      let data = {
        doctorNote: values.doctorVoiceNotes,
        patientId: this.props.match.params.id,
        voiceDiaryId: this.state.voiceDiaryId,
      };

      this.setState({ submitted: true });
      await self.props.addDoctorNotesOnVoiceDiary(data).then((response) => {
        if (response.error && response.error !== "") {
          notifyUser(response.error.message, "error");
          self.setState({ submitted: false });
        } else {
          if (response.data && response.data !== "") {
            notifyUser("Doctor note added Successfully", "success");
            self.setState({ submitted: false });
          }
        }
      });
    }
  };

  addDoctorNotesOnTextDiary = async (values) => {
    let self = this;
    this.setState({ formKey: (this.state.formKey || 0) + 1 });

    if (!self.state.textDiaryId || !self.state.textDiaryId) {
      notifyUser("Please select any diary", "error");
    } else {
      let data = {
        doctorNote: values.doctorTextNotes,
        patientId: this.props.match.params.id,
        textDiaryId: this.state.textDiaryId,
      };
      this.setState({ submitted: true });
      await self.props.addDoctorNotesOnTextDiary(data).then((response) => {
        if (response.error && response.error !== "") {
          notifyUser(response.error.message, "error");
          self.setState({ submitted: false });
        } else {
          if (response.data && response.data !== "") {
            notifyUser("Doctor note added Successfully", "success");
            self.setState({ submitted: false });
          }
        }
      });
    }
  };

  addDoctorNotesOnPhotoDiary = async (values) => {
    let self = this;
    this.setState({ formKey: (this.state.formKey || 0) + 1 });

    if (!self.state.photoDiaryId || !self.state.photoDiaryId) {
      notifyUser("Please select any diary", "error");
    } else {
      let data = {
        doctorNote: values.doctorTextNotes,
        patientId: this.props.match.params.id,
        photoDiaryId: this.state.photoDiaryId,
      };
      this.setState({ submitted: true });
      await self.props.addDoctorNotesOnPhotoDiary(data).then((response) => {
        if (response.error && response.error !== "") {
          notifyUser(response.error.message, "error");
          self.setState({ submitted: false });
        } else {
          if (response.data && response.data !== "") {
            notifyUser("Doctor note added Successfully", "success");
            self.setState({ submitted: false });
          }
        }
      });
    }
  };

  goToChat = () => {
    var patientId = this.props.match.params.id;
    var ref = firebase.firestore().collection("chatrooms");
    var type = Config.server.type;
    ref
      .where(`users.${patientId + type}`, "==", true)
      .get()
      .then((docs) => {
        let room = {};
        docs.forEach((snapshot) => {
          room = snapshot.data();
          room.id = snapshot.id;
        });
        this.props.history.push("/chat/" + patientId);
      });
  };

  goToDiary = (colId) => {
    this.setState({ selectedCol: colId });
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-diary/" + patientId);
  };

  goToEASiScoring = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-head/" + patientId);
  };

  goToPhotoGallery = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/photo-gallery/" + patientId);
  };

  goToAdverseEvents = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/adverse-events/" + patientId);
  };

  goToActivityData = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/activity-data/" + patientId);
  };

  goToPatientProgress = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/flares/" + patientId);
  };

  goToLab = (id) => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/lab/" + patientId);
  };

  async goToVoiceDiary(id, path, title) {
    this.setState({
      filepath: path,
      diaryTitle: title,
      voiceDiaryId: id,
      selectItemId: id,
    });
    //get doctor notes of this Voice Diary
    await this.props.getDoctorCommentsOnVoiceDiary(id);
  }

  async goToPhotoDiary(id, title, path) {
    this.setState({ photoLoading: true });
    await this.props.getPhotoDiaryImage(path);
    if (this.props.photoDiaryUrl) {
      this.setState({
        imageUrl: this.props.photoDiaryUrl[0].url,
        filepath: "",
        textDiaryTitle: "",
        photoDiaryTitle: title,
        photoDiaryId: id,
        selectItemId: id,
        photoLoading: false,
      });
    }
    //get doctor notes of this Photo Diary
    await this.props.getDoctorCommentsOnPhotoDiary(id);
  }

  showModal = (img) => {
    this.setState({ image: img, isModalVisible: true });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  async goToTextDiary(id, title, text) {
    this.setState({
      textDiaryTitle: title,
      textDiaryId: id,
      diarytext: text,
      filepath: "",
      imageUrl: "",
      selectItemId: id,
    });
    await this.props.getDoctorCommentsOnTextDiary(id);
  }

  goToStudyDrugLog = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/study-drug-log/" + patientId);
  };

  goToFlares = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/flares/" + patientId);
  }

  render() {
    const {
      submitted,
      filepath,
      imageUrl,
      photoDiaryTitle,
      photoDiaryId,
      photoLoading,
      diaryTitle,
      textDiaryTitle,
      diarytext,
      selectItemId,
      selectedCol,
      voiceDiary,
      photoDiary,
      textDiary
    } = this.state;

    const oldDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].dateOfBirth
    const dateType = !isNaN(Date.parse(oldDate))
    let finalDate = "";
    if (oldDate && dateType == true) {
      finalDate = moment(oldDate).format("MM/DD/YYYY")
    } else if (oldDate && dateType == false) {
      finalDate = moment(new Date(Number(oldDate))).format("MM/DD/YYYY")
    } else {
      finalDate = "N/A"
    }

    const studyId = localStorage.getItem("studyId");

    const patientData = this.props.patientData && this.props.patientData[0];


    // Patient Start End time
    const notificationEndDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate;
    const notificationTimezone = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationTimezone;
    const endDateInTimezone = moment.tz(notificationEndDate, notificationTimezone);
    let formattedTimeEnd = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate) {
      formattedTimeEnd = endDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeEnd = "";
    }
    const formattedTimeEndData = formattedTimeEnd;

    const notificationStartDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate;
    const startDateInTimezone = moment.tz(notificationStartDate, notificationTimezone);
    let formattedTimeStart = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate) {
      formattedTimeStart = startDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeStart = "";
    }
    const formattedTimeStartData = formattedTimeStart;
    // Patient Start End time end

    return (
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          // trigger={null}
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          // collapsible
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        // collapsed={this.state.collapsed}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background">
            <PageHeader
              className="site-header-title"
              title={
                this.state.data &&
                  this.state.data.title 
              }
            />

            <Spin spinning={submitted}>
              {!this.state.loading && (
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={6} xl={6}>
                    <div className="white-box user-section">
                      <div className="user-profile">
                        <Link to={"/patient-progress/" + this.props.match.params.id} >
                          <Avatar size={84}>
                            {this.props.patientData &&
                              this.props.patientData[0].firstName
                                .toUpperCase()
                                .charAt(0) +
                              this.props.patientData[0].lastName
                                .toUpperCase()
                                .charAt(0)}
                          </Avatar>
                        </Link>
                        <div className="profile-details">
                          <Link to={"/patient-progress/" + this.props.match.params.id} >
                            <h3>
                              {this.props.patientData &&
                                this.props.patientData[0].firstName +
                                " " +
                                this.props.patientData[0].lastName}
                            </h3>
                          </Link>
                          {
                            studyId == 1 ?
                            <button
                              className="btn"
                              onClick={() => this.goToChat()}
                            >
                              Chat Now
                            </button>
                            :
                            ""
                          }
                        </div>
                      </div>
                      <div className="user-details">
                        <List
                          style={{ marginTop: "40px" }}
                          itemLayout="horizontal"
                          dataSource={this.props.patientData}
                          renderItem={(patient) => (
                            <>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Study:
                                </Typography.Text>{" "}
                                  {patient && patient.title}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Email:
                                </Typography.Text>{" "}
                                {patient.email}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Date of Birth:
                                </Typography.Text>{" "}
                                {/* {patient.dateOfBirth
                                  ? moment(new Date(Number(patient.dateOfBirth))).format(
                                    "DD-MM-YYYY"
                                  )
                                  : "N/A"} */}
                                  {finalDate}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Age:
                                </Typography.Text>{" "}
                                {patient.age ? patient.age + " years" : "N/A"}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Phone Number:
                                </Typography.Text>{" "}
                                {"(" +
                                  patient.phoneCode +
                                  ") " +
                                  patient.phoneNumber}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Member Status:
                                </Typography.Text>{" "}
                                {patient.userScreeningStatus.replace("_", " ")}
                              </List.Item>
                              {
                                studyId == 2 ?
                                <List.Item>
                                  <Typography.Text style={{ color: "#006297" }}>
                                    Patient's TimeZone:
                                  </Typography.Text>{" "}
                                    {patient.notificationTimezone}
                                </List.Item>
                                : ""
                              }
                              {
                                studyId == 2 ?
                                <List.Item>
                                  <Typography.Text style={{ color: "#006297" }}>
                                    Notifications Time:
                                  </Typography.Text>{" "}
                                    {formattedTimeStartData} {!formattedTimeStartData && !formattedTimeEndData ? "" : "to"} {formattedTimeEndData}
                                </List.Item>
                                : 
                                ""
                              }
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Registered Date:
                                </Typography.Text>{" "}
                                {patient.createdDate
                                  ? moment(patient.createdDate)
                                    .format(dateFormathh)
                                  : "N/A"}
                              </List.Item>
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={18} xl={18}>
                    <Card
                      title={"Flare Diary"}
                      extra={
                        <Button onClick={() => this.goToPatientProgress()}>
                          Back
                        </Button>
                      }
                      style={{ marginBottom: "8px", padding: "0 16px" }}
                      className="patient-dairy-section"
                    >
                      <Row gutter={0}>
                        <Col xs={24} sm={24} md={8} xl={8}>
                          {/* Dairy Tabs START */}
                          <div className="card-container">
                            {/* <Tabs type="card">
                              <TabPane tab="Voice" key="1"> */}
                                <List
                                  type="card"
                                  itemLayout="horizontal"
                                  dataSource={voiceDiary}
                                  renderItem={(item) => (
                                    <List.Item
                                      key={item.id}
                                      onClick={() =>
                                        this.goToVoiceDiary(
                                          item.id,
                                          item.filePath,
                                          item.title
                                        )
                                      }
                                      className={
                                        selectItemId === item.id
                                          ? "selected-item-list"
                                          : "item-list"
                                      }
                                      style={{cursor: "pointer"}}
                                    >
                                      <List.Item.Meta
                                        avatar={
                                          <span className="date">
                                            {item.date
                                              ? moment(item.date).format(
                                                dateFormat
                                              )
                                              : "date"}
                                          </span>
                                        }
                                        title={
                                          item.title ? item.title : "title"
                                        }
                                        description={
                                          <p>
                                            {/* {item.duration && item.duration > 60
                                              ? item.duration / 60 + " mins"
                                              : item.duration + " sec"} */}
                                              {item.duration && item.duration == 60
                                            ? Math.floor(60 / 60) + " min" :
                                            item.duration && item.duration > 60
                                              // ? (item.duration / 60).toFixed(2) + " mins"
                                              ? Math.floor(item.duration / 60) + " mins " + (item.duration % 60) + " sec"
                                              : item.duration + " sec"}
                                          </p>
                                        }
                                      />
                                    </List.Item>
                                  )}
                                />
                              {/* </TabPane> */}
                              {/* <TabPane tab="Photos" key="2">
                                <List
                                  itemLayout="horizontal"
                                  dataSource={photoDiary}
                                  renderItem={(item) => (
                                    <List.Item
                                      key={item.id}
                                      onClick={() =>
                                        this.goToPhotoDiary(
                                          item.id,
                                          item.title,
                                          item.filePath
                                        )
                                      }
                                      className={
                                        selectItemId === item.id
                                          ? "selected-item-list"
                                          : "item-list"
                                      }
                                      style={{cursor: "pointer"}}
                                    >
                                      <List.Item.Meta
                                        avatar={
                                          <span className="date">
                                            {item.date
                                              ? moment(item.date).format(
                                                dateFormat
                                              )
                                              : "date"}
                                          </span>
                                        }
                                        title={item.title}
                                      />
                                    </List.Item>
                                  )}
                                />
                              </TabPane>
                              <TabPane tab="Text" key="3">
                                <List
                                  itemLayout="horizontal"
                                  dataSource={textDiary}
                                  renderItem={(item) => (
                                    <List.Item
                                      key={item.id}
                                      onClick={() =>
                                        this.goToTextDiary(
                                          item.id,
                                          item.title,
                                          item.text
                                        )
                                      }
                                      className={
                                        selectItemId === item.id
                                          ? "selected-item-list"
                                          : "item-list"
                                      }
                                      style={{cursor: "pointer"}}
                                    >
                                      <List.Item.Meta
                                        avatar={
                                          <span className="date">
                                            {item.date
                                              ? moment(item.date).format(
                                                dateFormat
                                              )
                                              : "date"}
                                          </span>
                                        }
                                        title={item.title}
                                      />
                                    </List.Item>
                                  )}
                                />
                              </TabPane> */}
                            {/* </Tabs> */}
                          </div>
                          {/* /Dairy Tabs END */}
                        </Col>
                        <Col xs={24} sm={24} md={16} xl={16}>
                          {filepath && filepath !== "" && (
                            <Col span={24}>
                              {/* Dairy Content Section Start */}
                              <div className="dairy-content-section">
                                <div className="play-icon-container">
                                  <img src={notesPlay} alt="Play" />
                                </div>
                                <Form
                                  key={this.state.formKey}
                                  layout="vertical"
                                  onFinish={this.addDoctorNotesOnVoiceDiary}
                                >
                                  <Form.Item
                                    label="Title"
                                    className="form-title-field"
                                  >
                                    <Input value={diaryTitle} disabled />
                                  </Form.Item>
                                  <Form.Item className="audio-section">
                                    <div style={{ marginBottom: "15px" }}>
                                      <img src={soundWaves} alt="Audio Waves" />
                                    </div>
                                    <AudioPlayer
                                      src={filepath}
                                      onPlay={(e) => console.log("onPlay")}
                                    // other props here
                                    />
                                  </Form.Item>
                                  {this.props.doctorvoiceComments &&
                                    this.props.doctorvoiceComments.length > 0 ? (
                                    <div className="previous-comment-section">
                                      <Form.Item label="Previous Comments">
                                        <div
                                          style={{
                                            maxHeight: "300px",
                                            overflow: "auto",
                                          }}
                                        >
                                          {this.props.doctorvoiceComments.map(
                                            (comment) => {
                                              return (
                                                <div>
                                                  <Comment
                                                    author={comment.doctorName}
                                                    content={comment.doctorNote}
                                                    datetime={
                                                      <Tooltip>
                                                        {moment(
                                                          comment.noteDate
                                                        ).format(dateFormathh)}
                                                      </Tooltip>
                                                    }
                                                  />
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </Form.Item>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <Form.Item
                                    name="doctorVoiceNotes"
                                    label="Doctor Notes"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please add comments on diary!",
                                        whitespace: true,
                                      },
                                    ]}
                                  >
                                    <Input.TextArea placeholder="Add comment..." />
                                  </Form.Item>

                                  <Form.Item className="btn-form-container">
                                    <Button type="primary" htmlType="submit">
                                      Submit
                                    </Button>
                                  </Form.Item>
                                </Form>
                              </div>
                              {/* /Dairy Content Section End */}
                            </Col>
                          )}
                          {textDiaryTitle &&
                            textDiaryTitle !== "" &&
                            filepath === "" ? (
                            <Col span={24}>
                              {/* Dairy Content Section Start */}
                              <div className="dairy-content-section">
                                <div className="play-icon-container"></div>
                                <Form
                                  key={this.state.formKey}
                                  layout="vertical"
                                  onFinish={this.addDoctorNotesOnTextDiary}
                                >
                                  <Form.Item
                                    label="Title"
                                    className="form-title-field"
                                  >
                                    <Input value={textDiaryTitle} disabled />
                                  </Form.Item>
                                  <Form.Item>
                                    <Input.TextArea
                                      value={diarytext}
                                    // other props here
                                    />
                                  </Form.Item>
                                  {this.props.doctorTextNotesList &&
                                    this.props.doctorTextNotesList.length > 0 ? (
                                    <div className="previous-comment-section">
                                      <Form.Item label="Previous Comments">
                                        <div
                                          style={{
                                            maxHeight: "300px",
                                            overflow: "auto",
                                          }}
                                        >
                                          {this.props.doctorTextNotesList.map(
                                            (comment) => {
                                              return (
                                                <div>
                                                  <Comment
                                                    author={comment.doctorName}
                                                    content={comment.doctorNote}
                                                    datetime={
                                                      <Tooltip>
                                                        {moment(
                                                          comment.noteDate
                                                        ).format(dateFormathh)}
                                                      </Tooltip>
                                                    }
                                                  />
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </Form.Item>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <Form.Item
                                    name="doctorTextNotes"
                                    label="Doctor Notes"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please add comments on diary!",
                                        whitespace: true,
                                      },
                                    ]}
                                  >
                                    <Input.TextArea
                                      placeholder="Add comment..."
                                      value={textDiaryTitle}
                                    />
                                  </Form.Item>

                                  <Form.Item className="btn-form-container">
                                    <Button type="primary" htmlType="submit">
                                      Submit
                                    </Button>
                                  </Form.Item>
                                </Form>
                              </div>

                              {/* /Dairy Content Section End */}
                            </Col>
                          ) : (
                            ""
                          )}
                          <Spin spinning={photoLoading}>
                            {imageUrl && imageUrl !== "" && filepath === "" ? (
                              <Col span={24}>
                                {/* Dairy Content Section Start */}
                                <div className="dairy-content-section">
                                  <div className="play-icon-container"></div>
                                  <Form
                                    key={this.state.formKey}
                                    layout="vertical"
                                    onFinish={this.addDoctorNotesOnPhotoDiary}
                                  >
                                    <Form.Item
                                      label="Title"
                                      className="form-title-field"
                                    >
                                      <Input value={photoDiaryTitle} disabled />
                                    </Form.Item>
                                    <Form.Item>
                                      <div>
                                        <img
                                          onClick={() =>
                                            this.showModal(imageUrl)
                                          }
                                          width={130}
                                          src={imageUrl}
                                          alt={photoDiaryTitle}
                                        />

                                        <Modal
                                          centered
                                          width={450}
                                          className="photo-zoom-popup"
                                          title={photoDiaryTitle}
                                          visible={this.state.isModalVisible}
                                          onCancel={this.handleCancel}
                                          footer={[<></>]}
                                        >
                                          <TransformWrapper
                                            defaultScale={1}
                                            defaultPositionX={1}
                                            defaultPositionY={1}
                                          >
                                            {({ zoomIn, zoomOut }) => (
                                              <>
                                                <PlusCircleFilled
                                                  size={30}
                                                  onClick={zoomIn}
                                                />
                                                <MinusCircleFilled
                                                  size={20}
                                                  onClick={zoomOut}
                                                />
                                                <TransformComponent>
                                                  <img
                                                    width={400}
                                                    src={imageUrl}
                                                    alt={photoDiaryTitle}
                                                  />
                                                </TransformComponent>
                                              </>
                                            )}
                                          </TransformWrapper>
                                        </Modal>
                                      </div>
                                    </Form.Item>
                                    {this.props.doctorPhotoNotesList &&
                                      this.props.doctorPhotoNotesList.length >
                                      0 ? (
                                      <div className="previous-comment-section">
                                        <Form.Item label="Previous Comments">
                                          <div
                                            style={{
                                              maxHeight: "300px",
                                              overflow: "auto",
                                            }}
                                          >
                                            {this.props.doctorPhotoNotesList.map(
                                              (comment) => {
                                                return (
                                                  <div>
                                                    <Comment
                                                      author={
                                                        comment.doctorName
                                                      }
                                                      content={
                                                        comment.doctorNote
                                                      }
                                                      datetime={
                                                        <Tooltip>
                                                          {moment(
                                                            comment.noteDate
                                                          ).format(
                                                            dateFormathh
                                                          )}
                                                        </Tooltip>
                                                      }
                                                    />
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </Form.Item>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    <Form.Item
                                      name="doctorTextNotes"
                                      label="Doctor Notes"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please add comments on diary!",
                                          whitespace: true,
                                        },
                                      ]}
                                    >
                                      <Input.TextArea
                                        placeholder="Add comment..."
                                        value={textDiaryTitle}
                                      />
                                    </Form.Item>

                                    <Form.Item className="btn-form-container">
                                      <Button type="primary" htmlType="submit">
                                        Submit
                                      </Button>
                                    </Form.Item>
                                  </Form>
                                </div>

                                {/* /Dairy Content Section End */}
                              </Col>
                            ) : (
                              ""
                            )}
                          </Spin>
                        </Col>
                      </Row>
                    </Card>

                    <ul className="link-list-box" style={{ display: "block" }}>
                      {
                        studyId == 1 ?
                        <li>
                          <div onClick={() => this.goToChat()} class="link-box">
                            <ChatIcon />
                            <h4>Chat</h4>
                          </div>
                        </li>
                        : 
                        ""
                      }
                      <li>
                        <div
                          onClick={() => this.goToEASiScoring()}
                          class="link-box"
                        >
                          <ServeyIcon />
                          <h4>Surveys &amp; Scores</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToDiary("diary")}
                          className={
                            selectedCol === "diary"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <DairyIcon />
                          <h4>Diary</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToPhotoGallery("gallery")}
                          className={
                            selectedCol === "gallery"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <PhotogalleryIcon />
                          <h4>Photo Gallery </h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToAdverseEvents("adverse")}
                          className={
                            selectedCol === "adverse"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <AdverseEventIcon />
                          <h4>Adverse Events</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToActivityData("activity")}
                          class="link-box"
                        >
                          <DataIcon />
                          <h4>Activity Data</h4>
                        </div>
                      </li>
                      {
                          studyId == 1 ?  
                          <li>
                            <div
                              onClick={() => this.goToStudyDrugLog("log")}
                              class="link-box"
                            >
                              <StudyDrugLogIcon />
                              <h4>Study Drug Log</h4>
                            </div>
                          </li>
                           : ""
                      }
                      {
                        studyId == 1 ?
                        <li>
                          <div
                            onClick={() => this.goToLab("lab")}
                            class="link-box"
                          >
                            <LabIcon />
                            <h4>Lab</h4>
                          </div>
                        </li> 
                      : ""
                      }
                      {
                        studyId == 4 && (patientData && patientData.healthCondition !== "HEALTHY_CONTROL") ?
                        <li>
                          <div
                            onClick={() => this.goToFlares()}
                            class="link-box"
                          >
                            <AuditOutlined style={{fontSize: "35px"}}/>
                            <h4>Flares</h4>
                          </div>
                        </li>
                        : ""
                      }
                    </ul>
                  </Col>
                </Row>
              )}
            </Spin>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientData: state.patient.patientData,
    patientVoiceDiary: state.diary.voiceDiaryList,
    doctorvoiceComments: state.diary.doctorVoiceNotesList,
    textDiaryList: state.diary.textDiaryList,
    photoDiaryList: state.diary.photoDiaryList,
    doctorTextNotesList: state.diary.doctorTextNotesList,
    photoDiaryUrl: state.diary.photoDiaryUrl,
    doctorPhotoNotesList: state.diary.doctorPhotoNotesList,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...PatientActions, ...PatientDiaryActions, ...StudyActions },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    FlaresDiary
  )
);
